<import from='@components/card-title/card-title-component' />
<import from='@components/card-header/card-header-component' />
<import from='@components/file-upload/file-upload-component' />
<import from='@components/tagify/basic/tagify-basic-component' />

<section>

  <card-title-component title-id="produtos">
    <div au-slot="actions">
      <div class="flex justify-end gap-3">
        <a href="/products" type="button" class="btn-neutral-outline-small"><i
            class="fa-solid fa-arrow-left mr-2"></i>Voltar</a>
        <button click.trigger="handleUpsertProduct()" class="btn-success-small">Salvar produto</button>
      </div>
    </div>
  </card-title-component>

  <div class="flex flex-col lg:flex-row gap-4 mb-6 mt-8">

    <!-- beggin:inputs -->
    <div class="card w-full lg:w-[60%] h-fit">
      <card-header-component header-id="produtos" show-popover.bind="false"></card-header-component>

      <form class="p-4" novalidate="novalidate">

        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">

          <div class="flex flex-col gap-4">
            <!-- beggin::alias -->
            <div class="flex-1">
              <label for="alias" class="form-label">Nome do produto ou serviço</label>
              <validation-container>
                <input type="text" id="alias" autocomplete="off" value.bind="alias & validate" class="form-input"
                  required>
              </validation-container>
            </div>
            <!-- end::alias -->

            <!-- beggin::linkConversion -->
            <div class="flex-1">
              <div class="flex flex-row justify-between">
                <label for="linkConversion" class="form-label">Link para página de conversão</label>

                <button if.bind="linkConversion" type="button" click.trigger="handleNavigateToPage()">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="size-4 mr-2 mb-2 text-gray-400 hover:text-gray-500">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
                  </svg>
                </button>

              </div>
              <input type="text" id="linkConversion" autocomplete="off" value.bind="linkConversion" class="form-input"
                required readonly>
            </div>
            <!-- end::linkConversion -->

            <div class="flex flex-row flex-1 gap-4 w-full">
              <!-- beggin::price -->
              <div class="flex-1">
                <label for="price" class="form-label">Preço cheio</label>
                <validation-container>
                  <input type="number" id="price" autocomplete="off" value.bind="price & validate" class="form-input"
                    required>
                </validation-container>
              </div>
              <!-- end::price -->

              <!-- beggin::salePrice -->
              <div class="flex-1">
                <label for="salePrice" class="form-label">Preço promocional</label>
                <validation-container>
                  <input type="number" id="salePrice" autocomplete="off" value.bind="salePrice & validate"
                    class="form-input" required>
                </validation-container>
              </div>
              <!-- end::salePrice -->
            </div>

            <!-- beggin::characteristics -->
            <div class="flex-1">
              <tagify-basic-component label="Características"
                tagify.two-way="characteristicTagify"></tagify-basic-component>
            </div>
            <!-- end::characteristics -->

            <!-- beggin::benefits -->
            <div class="flex-1">
              <tagify-basic-component label="Principais diferenciais"
                tagify.two-way="benefitsTagify"></tagify-basic-component>
            </div>
            <!-- end::benefits -->

            <!-- beggin::wordKeys -->
            <div class="flex-1">
              <tagify-basic-component label="Hashtags sobre o produto"
                tagify.two-way="wordKeysTagify"></tagify-basic-component>
            </div>
            <!-- end::wordKeys -->

          </div>

          <div class="flex flex-col gap-4">
            <!-- beggin::type -->
            <div>
              <label for="type" class="form-label">Natureza do produto</label>
              <validation-container>
                <select id="type" value.bind="type & validate" class="form-input">
                  <option model.bind="null">Selecione</option>
                  <option repeat.for="item of natureOfProductData" model.bind="item.value">${item.text}</option>
                </select>
              </validation-container>
            </div>
            <!-- end::type -->

            <!-- beggin::category -->
            <div>
              <label for="category" class="form-label">Categoria do produto ou serviço</label>
              <validation-container>
                <select id="category" value.bind="category & validate" class="form-input" disabled.bind="!type">
                  <option model.bind="null">Selecione</option>
                  <option repeat.for="item of categoryOfProductData[type]" model.bind="item.value">${item.text}</option>
                </select>
              </validation-container>
            </div>
            <!-- end::category -->

            <!-- beggin::description -->
            <div class="flex-1">
              <label for="description" class="form-label">Descrição do produto ou serviço</label>
              <validation-container>
                <textarea type="text" id="description" value.bind="description & validate" autocomplete="off"
                  class="form-input h-[calc(100%-2rem)]"></textarea>
              </validation-container>
            </div>
            <!-- end::description -->
          </div>

        </div>

        <div class="flex justify-end">
          <button click.trigger="handleUpsertProduct()" class="btn-primary">Salvar produto</button>
        </div>
      </form>

    </div>
    <!-- end:inputs -->

    <!-- beggin:outputs -->
    <div class="w-full lg:w-[40%] h-fit">

      <div class="grid grid-cols-2 gap-4 px-4">
        <div class="flex-1">
          <file-upload-component id="imageOne" label="Imagem do produto 1" current-file.two-way="imageOneFile"
            current-file-id.two-way="imageOneFileId" is-public="true"></file-upload-component>
        </div>
        <div class="flex-1">
          <file-upload-component id="imageTwo" label="Imagem do produto 2" current-file.two-way="imageTwoFile"
            current-file-id.two-way="imageTwoFileId" is-public="true"></file-upload-component>
        </div>
        <div class="flex-1">
          <file-upload-component id="imageThree" label="Imagem do produto 3" current-file.two-way="imageThreeFile"
            current-file-id.two-way="imageThreeFileId" is-public="true"></file-upload-component>
        </div>
        <div class="flex-1">
          <file-upload-component id="imageFour" label="Imagem do produto 4" current-file.two-way="imageFourFile"
            current-file-id.two-way="imageFourFileId" is-public="true"></file-upload-component>
        </div>

      </div>

    </div>
    <!-- end:outputs -->

  </div>

  <div data-popover id="popover-linkConversion" role="tooltip"
    class="absolute z-30 invisible inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
    <div class="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
      <h3 class="font-semibold text-gray-900 dark:text-white">Sobre a página de conversão:</h3>
    </div>
    <div class="px-3 py-2 text-xs">
      <p>É o link para onde você gostaria de levar o usuário que está vendo o conteúdo. Pode ser a página de checkout do
        seu produto, uma página de cadastro ou a página principal do seu site</p>
    </div>
    <div data-popper-arrow></div>
  </div>

</section>