import { bindable } from 'aurelia';
import { ModelTypes } from '@zeus';
import { watch } from "@aurelia/runtime-html";
import { IDialogService } from '@aurelia/dialog';
import { ICalendarService } from './services/calendar-service';
import { ViewCalendarEventDrawer } from './components/view-calendar-event-drawer/view-calendar-event-drawer';
import { eachDayOfInterval, endOfMonth, endOfWeek, isSameDay, parseISO, startOfMonth, startOfWeek } from 'date-fns';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import '@fullcalendar/core/locales/pt-br';
import { ViewCalendarEventDialog } from './components/view-calendar-event-dialog/view-calendar-event-dialog';
export class CalendarComponent {
  @bindable hideEvents = false;
  calendarHtmlElement
  calendar: Calendar;
  projects: ModelTypes["project"][] = [];

  viewCalendarEventDrawerRef: ViewCalendarEventDrawer;

  constructor(
    @ICalendarService private service: ICalendarService,
    @IDialogService private dialogService: IDialogService
  ) { }

  attached() {
    this.calendar = new Calendar(this.calendarHtmlElement, {
      plugins: [dayGridPlugin],
      themeSystem: 'united',
      locale: 'pt-br',
      moreLinkText: (num) => `+${num} mais`,
      buttonText: {
        today: 'Hoje',
        month: 'Mês',
        week: 'Semana',
        day: 'Dia',
        list: 'Lista'
      },
      initialView: 'dayGridMonth',
      events: this.getEvents.bind(this),
      dayMaxEvents: 4,
      eventClick: (async function (event) {
        const extendedProps = event.event.extendedProps;
        const { dialog } = await this.dialogService.open({
          component: () => ViewCalendarEventDialog,
          model: { project: extendedProps },
          lock: false,
          keyboard: ['Escape'],
          startingZIndex: 100,
        });
        // this.openViewCalendarEventDialog(extendedProps)

      }).bind(this)
    });
    this.calendar.render();
  }

  private async openViewCalendarEventDialog(project) {
    this.viewCalendarEventDrawerRef.drawerShow(project);
    console.log(project);
  }
  private async getEvents(info, successCallback, failureCallback) {
    const start = info.startStr;
    const end = info.endStr;
    const projects = await this.service.getProjects(start, end);
    const events = projects.map(project => {
      return {
        title: project.caption,
        start: project.publishDate || project.scheduledDate || project.suggestedScheduledDate || project.created_at,
        end: project.created_at,
        allDay: true,
        extendedProps: project
      }
    }
    );
    successCallback(events);
  }

}