import { newInstanceForScope } from '@aurelia/kernel';
import { IRouter, Navigation, RoutingInstruction } from '@aurelia/router';
import { IValidationRules } from '@aurelia/validation';
import { IValidationController } from '@aurelia/validation-html';
import { QuillComponent } from '@components/quill/quill-component';
import { ModelTypes } from '@zeus';
import { IEventAggregator, bindable } from 'aurelia';
import { nhost } from 'lib/nhost';
import { ITextModuleService } from './services/text-module-services';
import { toastifyError, toastifySuccess, toastifyWarning } from 'common/helpers/toastify';
import { IDialogService } from '@aurelia/dialog';
import { SaveTextDialog } from './components/save-text-dialog/save-text-dialog';
import slugify from 'slugify';

export class TextModule {
  @bindable content: string;
  @bindable blogTitle: string;

  @bindable imageFile: File;
  @bindable imageFileId: string;

  private textId: string;
  private base64Data: string;
  private imageResponse: string;

  quillRef: QuillComponent;

  private brandId: string = null;
  private productId: string = null;
  private audienceId: string = null;

  private prompt: string;
  private selectedBrandId: string;
  private selectedProductId: string;

  private brands: ModelTypes['brands'][];
  private products: ModelTypes['products'][];

  private slug: string;
  private format: string;
  private voiceTone: string;
  private category: string;
  private textStyle: string;

  constructor(
    @IRouter private router: IRouter,
    @IEventAggregator readonly ea: IEventAggregator,
    @IDialogService private dialogService: IDialogService,
    @ITextModuleService private service: ITextModuleService,
    @IValidationRules readonly validationRules: IValidationRules,
    @newInstanceForScope(IValidationController) readonly validationController: IValidationController
  ) {
    validationRules
      .on(this)
      .ensure('blogTitle')
      .required().withMessage('Informe o nome o titulo')

      .ensure('content')
      .required().withMessage('Informe o texto')

      .ensure('imageFile')
      .satisfies((value: File) => (value !== null && value !== undefined) || (this.imageFileId !== null)).withMessage('Insira uma imagem')
  }

  async loading(params: { id: string; }, instruction: RoutingInstruction, navigation: Navigation) {
    if (params.id) {
      this.textId = params.id;
    }
  }

  async attached() {
    if (this.textId) {
      const response = await this.service.getProjectById(this.textId);

      this.content = response?.blogContent;
      this.blogTitle = response?.blogTitle;

      if (!response.featuredImageFileId) return

      this.imageFileId = response.featuredImageFileId;

      const featureImageFileId = response?.featuredImageFileId
      const url = nhost.storage.getPublicUrl({ fileId: featureImageFileId });

      this.base64Data = url
    } else {
      const userBrands = await this.service.brands();
      if (userBrands.length === 1) {
        this.brandId = userBrands[0].id;
      }
    }

    this.handleLoadData();

    this.selectedBrandId = this.brandId;
    this.selectedProductId = this.productId;
  }

  private async handleLoadData(): Promise<void> {
    this.brands = await this.service.brands();
    this.products = await this.service.products();
  }

  blogTitleChanged() {
    this.slug = this.blogTitle;
  }

  async addProjectBlog() {
    const result = await this.validationController.validate();

    if (!result.valid) {
      toastifyWarning({ message: 'Preencha todos os campos' });

      return;
    }

    try {

      if (!this.imageFileId) {
        const imageBannerBlogResponse = await nhost.storage.upload({ file: this.imageFile, bucketId: 'public' });

        this.imageFileId = imageBannerBlogResponse.fileMetadata.id;
      }

      this.content = this.quillRef.quill.root.innerHTML;

      const resultMutation = await this.service.insertProject({
        id: this.textId,
        alias: '',
        rawText: '',
        blogTitle: this.blogTitle,
        blogContent: this.content,
        featuredImageFileId: this.imageFileId,
        brandId: this.selectedBrandId,
        slug: slugify(this.slug, { strict: true, lower: true }),
      });

      toastifySuccess({ message: 'Projeto salvo com sucesso' });

      this.router.load(`/text/${resultMutation.id}`)
    } catch (e) {
      toastifyError({ message: e });
    }
  }

  async filesChanged(event: { target: { files: FileList }; }) {
    if (!event.target.files) return
    this.imageFile = event.target.files[0]

    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      this.base64Data = fileReader.result as string;
    }
    if (this.imageFile) {
      fileReader.readAsDataURL(this.imageFile);
    }
  }

  private async getFileURL(fileId: string): Promise<string> {
    const { presignedUrl } = await nhost.storage.getPresignedUrl({ fileId, width: 100, height: 100 })

    return presignedUrl.url;
  }

  private downloadTextFile() {
    const content = this.quillRef.quill.root.innerHTML;

    const blob = new Blob([content], { type: 'text/plain' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'text.txt';

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }

  private async generateFromIA(): Promise<void> {
    try {
      const mutation = await this.service.blogPostGeneratorEnhancedForAmp({
        brief: this.prompt,
        brandName: this.brands.find((brand) => brand.id === this.selectedBrandId).brandName,
        productName: this.products.find((product) => product.id === this.selectedProductId).alias,
        title: this.blogTitle,
        format: this.format,
        voiceTone: this.voiceTone,
        category: this.category,
        textStyle: this.textStyle,
      });

      if (!mutation || !mutation.html) throw new Error('Erro ao gerar texto');

      this.content = mutation.html;
    } catch (error) {
      toastifyError({ message: error });
    }
  }

  private async handlePublish(): Promise<void> {
    await this.dialogService.open({
      component: () => SaveTextDialog,
      model: { projectId: this.textId },
      lock: false,
      startingZIndex: 100,
    });
  }
}