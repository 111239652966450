import { DI } from 'aurelia';
import { uuid4 } from 'common/helpers/crypto/crypto';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { blog_constraint, blog_update_column, ModelTypes, project_constraint, project_update_column } from '@zeus';

interface IblogPostGeneratorEnhancedForAmp {
  brief: string
  brandName: string
  productName: string
  format: string
  title: string
  voiceTone: string
  category: string
  textStyle: string
}

type projectInsertInput = ModelTypes['project_insert_input'] & {
  slug: string
}

type projectPublishOrSchedule = {
  projectId: string
  scheduledDate: Date,
  publishDate: Date,
}

export interface ITextModuleService {
  brands(): Promise<Array<ModelTypes['brands']>>
  getProjectById(id: string): Promise<ModelTypes["project"]>
  products(): Promise<Array<ModelTypes['products']>>
  audiences(): Promise<Array<ModelTypes['audiences']>>
  insertProject(command: projectInsertInput): Promise<ModelTypes["project"]>
  blogPostGeneratorEnhancedForAmp(command: IblogPostGeneratorEnhancedForAmp): Promise<ModelTypes["blogPostGeneratorEnhancedForAmpOutput"]>
  publishOrScheduleProject(command: projectPublishOrSchedule): Promise<ModelTypes["project"]>
}

class TextModuleService implements ITextModuleService {

  constructor(@IZeusClient private zeusClient: ZeusClient) { }

  async getProjectById(id: string): Promise<ModelTypes["project"]> {
    const result = await this.zeusClient.chain('query')({
      project_by_pk: [
        { id: id },
        {
          id: true,
          blogTitle: true,
          blogContent: true,
          featuredImageFileId: true,
          previewImageFileId: true,
        }
      ]
    });
    return result?.project_by_pk as ModelTypes["project"];
  }

  async brands(): Promise<Array<ModelTypes['brands']>> {
    const result = await this.zeusClient.chain('query')({
      brands: [
        {},
        {
          id: true,
          brandName: true,
        },
      ]
    });

    return result?.brands as Array<ModelTypes['brands']>;
  }

  async products(): Promise<Array<ModelTypes['products']>> {
    const result = await this.zeusClient.chain('query')({
      products: [
        {},
        {
          id: true,
          alias: true,
        },
      ]
    });

    return result?.products as Array<ModelTypes['products']>;
  }

  async audiences(): Promise<Array<ModelTypes['audiences']>> {
    const result = await this.zeusClient.chain('query')({
      audiences: [
        {},
        {
          id: true,
          brandName: true,
        },
      ]
    });

    return result?.audiences as Array<ModelTypes['audiences']>;
  }

  async insertProject(command: projectInsertInput): Promise<ModelTypes["project"]> {

    if (!command.id) {
      command.id = uuid4();
    }

    const result = await this.zeusClient.chain('mutation')({
      insert_project_one: [
        {
          object: {
            id: command.id,
            alias: command.alias,
            rawText: command.rawText,
            blogTitle: command.blogTitle,
            blogContent: command.blogContent,
            previewImageFileId: command.featuredImageFileId,
            featuredImageFileId: command.featuredImageFileId,
            type: 'text',
            creditPrice: 0,
          },
          on_conflict: {
            constraint: project_constraint.project_pkey,
            update_columns: [
              project_update_column.id,
              project_update_column.alias,
              project_update_column.rawText,
              project_update_column.blogTitle,
              project_update_column.blogContent,
              project_update_column.previewImageFileId,
              project_update_column.featuredImageFileId,
              project_update_column.type,
              project_update_column.creditPrice,
            ]
          }
        },
        {
          id: true
        }
      ],

      insert_blog_one: [
        {
          object: {
            id: command.id,
            projectId: command.id,
            title: command.blogTitle,
            slug: command.slug,
            content: command.blogContent,
            featuredImageId: command.featuredImageFileId,
            brandId: command.brandId,
            categoryId: '386b5c6a-8a10-4d78-aba0-1b6002768d72',
            metaDescription: command.blogContent.substring(0, 160),
          },
          on_conflict: {
            constraint: blog_constraint.blog_pkey,
            update_columns: [
              blog_update_column.id,
              blog_update_column.projectId,
              blog_update_column.title,
              blog_update_column.slug,
              blog_update_column.content,
              blog_update_column.featuredImageId,
              blog_update_column.brandId,
              blog_update_column.categoryId,
              blog_update_column.metaDescription
            ]
          }
        },
        {
          id: true
        }
      ]
    });

    return result?.insert_project_one as ModelTypes["project"];
  }

  async publishOrScheduleProject(command: projectPublishOrSchedule): Promise<ModelTypes["project"]> {

    const result = await this.zeusClient.chain('mutation')({
      update_project_by_pk: [
        {
          pk_columns: {
            id: command.projectId
          },
          _set: {
            scheduledDate: command.scheduledDate,
            publishDate: command.publishDate,
          }
        },
        {
          id: true
        }
      ],

      update_blog_by_pk: [
        {
          pk_columns: {
            id: command.projectId
          },
          _set: {
            scheduledDate: command.scheduledDate,
            publishDate: command.publishDate,
          }
        },
        {
          id: true
        }
      ]
    });

    return result?.update_project_by_pk as ModelTypes["project"];
  }

  async blogPostGeneratorEnhancedForAmp(command: IblogPostGeneratorEnhancedForAmp): Promise<ModelTypes["blogPostGeneratorEnhancedForAmpOutput"]> {
    const result = await this.zeusClient.chain('mutation')({
      blogPostGeneratorEnhancedForAmp: [
        {
          brief: command.brief,
          brandName: command.brandName,
          productName: command.productName,
          format: command.format,
          title: command.title,
          voiceTone: command.voiceTone,
          category: command.category,
          textStyle: command.textStyle

        },
        {
          html: true
        }
      ]
    });

    return result?.blogPostGeneratorEnhancedForAmp as ModelTypes["blogPostGeneratorEnhancedForAmpOutput"];
  }
}

export const ITextModuleService = DI.createInterface<ITextModuleService>(x => x.singleton(TextModuleService));
