<import from='@components/undraw/undraw-component' />
<import from='@components/card-title/card-title-component' />

<import from='./components/table-actions/table-actions-component' />
<import from='./components/product-thumbnail-image/product-thumbnail-image-component' />

<section>
  <card-title-component title-id="produtos">
    <div au-slot="actions">
      <div class="flex justify-end gap-3">
        <a href="/product" type="button" class="btn-success-small">Criar produto</a>
      </div>
    </div>
  </card-title-component>

  <template if.bind="products.length === 0">
    <undraw-component message="Nenhum produto encontrado, Clique em 'Criar produto'"></undraw-component>
  </template>
  <template else>
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-4 mb-6">
      <template repeat.for="item of products">

        <!-- begin::card -->
        <div class="card h-full">
          <div class="flex flex-col h-full">
            <div class="flex items-center p-4 text-gray-900 dark:text-white gap-3">

              <product-thumbnail-image-component product.bind="item"></product-thumbnail-image-component>

              <div class="">
                <div class="text-base font-semibold line-clamp-1">${item.alias ?? '...'}</div>
                <div class="text-sm font-normal text-gray-500">${item.type ?? '...'}</div>
              </div>
            </div>

            <div class="border-y dark:border-gray-700 p-4 grow">
              <template repeat.for="color of parseColor(item.colors)">
                <span
                  class="relative z-20 inline-flex items-center justify-center w-7 h-7 cursor-pointer transition-all duration-200 border border-gray-200 dark:border-gray-700 border-solid ease-in-out rounded-md hover:z-30 ${$index > 0 ? '-ml-4' : ''}"
                  style.bind="`background-color: ${color.backgroundColor ? color.backgroundColor : 'gray'};`">
                  <i class="fa-solid fa-droplet"
                    style.bind="`color: ${color.foregroundColor ? color.foregroundColor : 'white'};`"></i>
                </span>
              </template>
              <p class="text-sm font-normal text-gray-700 dark:text-gray-400 mt-3 line-clamp-2">${item.description ??
                '...'}
              </p>
            </div>

            <div class="flex flex-wrap justify-between items-center p-4 gap-3">
              <div class="flex flex-col gap-1 w-fit">
                <div class="flex flex-col justify-start items-start gap-1">
                  <span class="text-xs font-normal text-gray-500 text-right">${item.created_at |
                    date:I18N.getLocale().replace('_', '-') & signal:'parameterChanged' }</span>
                </div>
              </div>

              <table-actions-component product.bind="item"
                delete-callback.bind="() => deleteProduct(item.id)"></table-actions-component>

            </div>

          </div>

        </div>
        <!-- end::card -->

      </template>
    </div>
  </template>
  </div>
</section>